import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Image } from "react-bootstrap";
import TabsLucrare from "../tabs_lucrare/TabsLucrare";
import {
  getInterventiiService,
  readLucrariService
} from "../backend/LucrareService";
import LucrareItem from "../lucrare/LucrareItem";
import { RefreshContext } from "../context/RefreshContext";
import logo_image from "../imgs/logo.png";
import { refreshTimer, readLucrariTimer } from "../utils/Values";

const Interventii = () => {
  const [interventii, setInterventii] = useState([]);
  const [currentLucrare, setCurrentLucrare] = useState();
  const [activeTab, setActiveTab] = useState("locatie");
  const {
    triggerInterventii,
    refreshInterventii,
    internet,
    serverError,
    refreshNumere
  } = useContext(RefreshContext);
  const [refresh, setRefresh] = useState(false);
  const [isReadLucrari, setIsReadLucrari] = useState(false);

  const setTab = value => {
    setActiveTab(value);
  };

  const getInterventii = async () => {
    try {
      const result = await getInterventiiService();
      setInterventii(result);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const id = startRefreshTimer();
    return () => {
      clearInterval(id);
    };
  }, [refresh]);

  const startRefreshTimer = () => {
    return setInterval(() => {
      setRefresh(!refresh);
    }, refreshTimer);
  };

  useEffect(() => {
    getInterventii();
  }, [triggerInterventii, refresh]);

  const readLucrari = async () => {
    try {
      if (!isReadLucrari) {
        await readLucrariService("interventii");
        refreshInterventii();
        refreshNumere();
        setIsReadLucrari(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const markLucrariRead = timeOut => {
    return setTimeout(() => {
      readLucrari();
    }, timeOut);
  };

  useEffect(() => {
    const id = markLucrariRead(readLucrariTimer);
    return () => {
      clearInterval(id);
      readLucrari();
    };
  }, []);

  return (
    <Row>

      <Col md={9}>
        {internet && !serverError ? (
          <>
            <Row className={"mb-3"}>
              <div className="headerWrapper">
                <div className="interventii_header">
                  <h2>Interventii</h2>
                </div>
              </div>
            </Row>
            <Row className="middleScreen" onClick={readLucrari}>
              <Col>
                {interventii.map((lucrare, index) => (
                  <LucrareItem
                    key={index}
                    refreshLucrari={refreshInterventii}
                    lucrare={lucrare}
                    currentLucrare={currentLucrare}
                    setCurrentLucrare={setCurrentLucrare}
                    setTab={setTab}
                  />
                ))}
              </Col>
            </Row>
          </>
        ) : (
          <div className="no_internet_wrapper">
            <Image src={logo_image} />
            <div className="no_internet_text">
              {serverError
                ? `Nu este conexiune la server`
                : `Nu este conexiune la internet`}
            </div>
          </div>
        )}
      </Col>

      <Col
        md={3}
        className="lineRightDashboard"
        style={{ marginRight: "0px", paddingRight: "0px" }}
      >
        <TabsLucrare
          currentLucrare={currentLucrare}
          setCurrentLucrare={setCurrentLucrare}
          activeTab={activeTab}
          setTab={setTab}
          refreshLucrari={refreshInterventii}
        />
      </Col>
    </Row>
  );
};

export default Interventii;

import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Image } from "react-bootstrap";
import TabsLucrare from "../tabs_lucrare/TabsLucrare";
import Lucrare from "../lucrare/Lucrare";
import ModalComponent from "./basic/ModalComponent";
import {
  getLucrariAdministrativNotificariService,
  getLucrariByTypeService,
  readLucrariService,
} from "../backend/LucrareService";
import LucrareItem from "../lucrare/LucrareItem";
import { RefreshContext } from "../context/RefreshContext";
import logo_image from "../imgs/logo.png";
import { refreshTimer, readLucrariTimer } from "../utils/Values";

const Notificari = () => {
  const [showModal, setShowModal] = useState(false);
  const [lucrari, setLucrari] = useState([]);
  const [triggerLucrari, setTriggerLucrari] = useState(false);
  const [currentLucrare, setCurrentLucrare] = useState();
  const [activeTab, setActiveTab] = useState("locatie");
  const [refresh, setRefresh] = useState(false);
  const { internet, serverError, refreshNumere } = useContext(RefreshContext);
  const [isReadLucrari, setIsReadLucrari] = useState(false);

  const setTab = (value) => {
    setActiveTab(value);
  };

  const refreshLucrari = () => {
    setTriggerLucrari(!triggerLucrari);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const getLucrari = async () => {
    try {
      const lucrari_clasice = await getLucrariByTypeService("notificari");
      const result = await getLucrariAdministrativNotificariService();

      let lucrari_de_discutat = result.map((lucrare) => {
        return {
          ...lucrare.lucrareDTO,
          discutieId: lucrare.uid,
          discutieDescriere: lucrare.descriere,
          discutieCreator: lucrare.userCreator,
          discutieCreateDate: new Date(lucrare.createdDate),
          discutieParticipanti: lucrare.useriConfirmati
            ? [...lucrare.useriConfirmati, ...lucrare.useriParticipanti]
            : lucrare.useriParticipanti,
          discutieConfirmati: lucrare.useriConfirmati,
        };
      });

      let lucrari = [...lucrari_clasice, ...lucrari_de_discutat];
      setLucrari(
        lucrari.sort((a, b) => {
          return !a.atribuiti - !b.atribuiti || !a.discutieId - !b.discutieId;
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const id = startRefreshTimer();
    return () => {
      clearInterval(id);
    };
  }, [refresh]);

  const startRefreshTimer = () => {
    return setInterval(() => {
      setRefresh(!refresh);
    }, refreshTimer);
  };

  useEffect(() => {
    getLucrari();
  }, [triggerLucrari, refresh]);

  const readLucrari = async () => {
    try {
      if (!isReadLucrari) {
        await readLucrariService("notificari");
        refreshLucrari();
        refreshNumere();
        setIsReadLucrari(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const markLucrariRead = (timeOut) => {
    return setTimeout(() => {
      readLucrari();
    }, timeOut);
  };

  useEffect(() => {
    const id = markLucrariRead(readLucrariTimer);
    return () => {
      clearInterval(id);
      readLucrari();
    };
  }, []);

  return (
    <Row>
      <Col md={9}>
        {internet && !serverError ? (
          <>
            <Row className={"mb-3"}>
              <div className="headerWrapper">
                <div
                  className="notificari_header"
                  onClick={() => setShowModal(true)}
                >
                  <h2>Notificari</h2>
                </div>
              </div>
            </Row>
            <Row className="middleScreen" onClick={readLucrari}>
              <Col>
                {lucrari.map((lucrare, index) => (
                  <LucrareItem
                    key={index}
                    refreshLucrari={refreshLucrari}
                    lucrare={lucrare}
                    currentLucrare={currentLucrare}
                    setCurrentLucrare={setCurrentLucrare}
                    setTab={setTab}
                  />
                ))}
              </Col>
            </Row>
            <ModalComponent
              show={showModal}
              close={closeModal}
              headingText="Lucrare Notificari"
              body={
                <Lucrare
                  closeModal={closeModal}
                  locationType="notificari"
                  refreshLucrari={refreshLucrari}
                />
              }
            />
          </>
        ) : (
          <div className="no_internet_wrapper">
            <Image src={logo_image} />
            <div className="no_internet_text">
              {serverError
                ? `Nu este conexiune la server`
                : `Nu este conexiune la internet`}
            </div>
          </div>
        )}
      </Col>
      <Col
        md={3}
        className="lineRightDashboard"
        style={{ marginRight: "0px", paddingRight: "0px" }}
      >
        <TabsLucrare
          locationType="notificari"
          currentLucrare={currentLucrare}
          setCurrentLucrare={setCurrentLucrare}
          activeTab={activeTab}
          setTab={setTab}
          refreshLucrari={refreshLucrari}
        />
      </Col>
    </Row>
  );
};

export default Notificari;

import React from "react";
import { Row, Col } from "react-bootstrap";
import "../style/LucrareItem.css";
import { useHistory } from "react-router-dom";

const NewMediaItem =({
  lucrareFolder,
  locationType,
  locationName,
  locationId,
  locationFolderName,
  lucrareFolderName
}) => {
  const history = useHistory();

  return (
    <Row
      className={
        lucrareFolder.seen === false
          ? "newLucrare"
          : "mb-1"
      }
    >
      <Row
        className={"row_folder" }
        style={{
          backgroundColor: getBackgroundColorLocatie(lucrareFolder?.locatie?.domeniu?.name),
          marginLeft: 0.1
        }}
        onClick={() => {
          history.push("/newMediaSlider", {
            lucrareFolder: lucrareFolder,
            locatieType: locationType,
            locatieId: locationId,
            locatieName: locationName,
            locatieFolderName: locationFolderName,
            lucrareFolderName: lucrareFolderName,
          });
        }}
      >
        <Col className="lucrare_item_locatie"  style={{ background: "#ffe7b3" }} md={4}>
          {lucrareFolder?.locatie?.name}
        </Col>

        <Col
          className="lucrare_folder_data"
          style={{ background: "#87cefa" }}
          md={8}
        >
          {lucrareFolder.name}
        </Col>
      </Row>
    </Row>
  );
};
export default NewMediaItem;

const getBackgroundColorLocatie = (type) => {
  switch (type) {
    case "interventii":
      return "#b0b0b0";
    case "ascensoare":
      return "#9ec1e0";
    case "interfoane":
      return "#a6d8b9";
    case "psi":
      return "#f1b2ac";
    case "altele":
      return "#ffcc66";
    case "sediu":
      return "#ffe7b3";
    case "diverse":
      return "#ffe7b3";
    default:
      return "#fffff5";
  }
};
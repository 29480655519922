import React, { useContext, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import ButtonComponent from "../components/basic/ButtonComponent";
import RightComponentWrapper from "../components/basic/RightComponentWrapper";
import { UserContext } from "../context/UserContext";
import ModalComponent from "../components/basic/ModalComponent";
import {
  deleteLocationService,
  setActiveService,
  getLocationByTypeService,
  getLocationsService,
  setRevizieSpecialaService,
  editCifruLocationService,
} from "../backend/LocationService";
import { useHistory } from "react-router-dom";
import Location from "../locations/Location";
import { getLocationService } from "../backend/LocationService";
import Switch from "react-switch";
import Select from "react-select";
import { database } from "../firebase";
import { mapMonth, isLocatieCar, showDateFormatFullYear } from "../utils/Utils";
import ConfirmActionComponent from "../components/basic/ConfirmActionComponent";
import { ref, get } from 'firebase/database';
import { generateRandomFourDigits } from "../components/CifruUtils";

const Locatie = ({
  currentLocation,
  setCurrentLocation,
  refreshLocations,
  locationType,
}) => {
  const { isAdmin, isDispecer, user } = useContext(UserContext);
  const [locatieModal, setLocatieModal] = useState(false);
  const history = useHistory();
  const [locations, setLocations] = useState([]);
  const [deleteButtonPressed, setDeleteButtonPressed] = useState(false);

  const getLocation = async () => {
    try {
      const result = await getLocationService(currentLocation.id);
      setCurrentLocation(result);
    } catch (err) {
      console.log(err);
    }
  };

  //pentru ca nu face refresh dupa salvare
  useEffect(() => {
    if (locatieModal === false && currentLocation) getLocation();
  }, [locatieModal]);

  useEffect(() => {
    getLocations();
  }, []);

  const closeLocatieModal = () => {
    setLocatieModal(false);
  };

  const rightClickLocatie = (e) => {
    e.preventDefault();
    setLocatieModal(true);
  };

  const deleteLocation = async () => {
    try {
      await deleteLocationService(currentLocation.id);
      setCurrentLocation(null);
      refreshLocations();
    } catch (err) {
      console.log(err);
    }
  };

  const changeActive = async () => {
    try {
      if (currentLocation){
        currentLocation.activ = !currentLocation.activ;
        validateRevSpecialaAndActive();
        await setActiveService(currentLocation.id, currentLocation.activ);
      
      refreshLocations();
    }
    } catch (err) {
      console.error(err);
    }
  };

  const validateRevSpecialaAndActive = async() => {
    if(currentLocation && currentLocation.revSpec && !currentLocation.activ){
     currentLocation.revSpec = false;
     await setRevizieSpecialaService(currentLocation.id, currentLocation.revSpec);
    } else if (currentLocation && !currentLocation.activ && currentLocation.revSpec){
      currentLocation.revSpec = !currentLocation.revSpec;
      await setRevizieSpecialaService(currentLocation.id, currentLocation.revSpec);
    }
  }

  const changeActiveRevSpec = async () => {
    try {
      if (currentLocation){
      currentLocation.revSpec = !currentLocation.revSpec;
      validateRevSpecialaAndActive();
      await setRevizieSpecialaService(currentLocation.id, currentLocation.revSpec);
      refreshLocations();
    }
    } catch (err) {
      console.error(err);
    }
  };

  const changeActivCifru = async () => {
    try {
      if (currentLocation){
      currentLocation.hasCifru = !currentLocation.hasCifru;
      await editCifruLocationService(currentLocation.id, {
        hasCifru: currentLocation.hasCifru,
        cifruChange: false,
        cifru: generateRandomFourDigits()
      });
      refreshLocations();
    }
    } catch (err) {
      console.error(err);
    }
  };

  const getLocations = async () => {
    try {
      const result = locationType
        ? await getLocationByTypeService(locationType)
        : await getLocationsService();
      setLocations(
        result.map((locatie) => {
          return {
            label: locatie.locatie,
            value: locatie.id,
            temporar: locatie.temporar,
            type: locatie.type,
            locatie: locatie.locatie,
          };
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const getBackgroundColorLocatie = (type) => {
    switch (type) {
      case "interventii":
        return "#b0b0b0";
      case "ascensoare":
        return "#9ec1e0";
      case "interfoane":
        return "#a6d8b9";
      case "psi":
        return "#f1b2ac";
      case "altele":
        return "#ffcc66";
      case "sediu":
        return "#ffe7b3";
      case "diverse":
        return "#ffe7b3";
      default:
        return "#fffff5";
    }
  };

  const customStyles = {
    singleValue: (provided, state) => {
      const color = "white";

      return { color };
    },
    option: (provided, state) => {
      const color = state.data.temporar ? "#c60800" : "black";
      return {
        color,
        backgroundColor: getBackgroundColorLocatie(state.data.type),
        fontSize: 18,
        paddingBottom: "5px",
        WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",

        active: { backgroundColor: "green" },
      };
    },
    menu: (provided, state) => {
      const display = state.options.length === 0 ? "none" : "block";
      return {
        display,
        boxSizing: "border-box",
        position: "absolute",
        backgroundColor: "#6c757d",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "10",
      };
    },
  };
  const addNrTelefon = () => {
    if (isAdmin() || isDispecer()) {
      const itemsRef = ref(database, "logics");
            get(itemsRef).then(snapshot => {
        let items = snapshot.val();
        if (items === null) {
          if (currentLocation?.tel) {
            itemsRef.push({userName: user.userRoleDTO.userName,
              nr: currentLocation.tel,
            });
          }
        } else {
          for (let item in items) {
            if (items[item].userName === user.userRoleDTO.userName) {
              return;
            } else {
              if (currentLocation?.tel) {
                itemsRef.push({
                  userName: user.userRoleDTO.userName,
                  nr: currentLocation.tel,
                });
              }
            }
          }
        }
      });
    }
  };
  return (
    <>
      <Row className="locatie_lucrare_wrapper_big">
        <Col className="text-center">
          <div className="locatie_istoric_wrapper">
            <div
              className="d-inline-block mr-2 istoric_locatie_button"
              onClick={() => {
                if (currentLocation) {
                  history.push("/istoricLocatie", {
                    locatieId: currentLocation.id,
                    locationType: locationType,
                    locatieName: currentLocation.locatie,
                  });
                }
              }}
            >
              <p className="rightComponentLabel">Istoric</p>
            </div>
            <div className="locatie_istoric_select">
              <div className="locatie_istoric_select_item">
                <Select
                  onMenuOpen={() => {
                    if (currentLocation) {
                      history.push("/istoricLocatie", {
                        locatieId: currentLocation.id,
                        locationType: locationType,
                        locatieName: currentLocation.locatie,
                      });
                    }
                  }}
                  options={locations}
                  styles={customStyles}
                  placeholder={
                    currentLocation?.locatie
                      ? currentLocation.locatie
                      : locationType === "comenzi"
                      ? "Furnizor"
                      : "Locatie"
                  }
                  formatCreateLabel={() => null || undefined}
                  noOptionsMessage={() => null}
                  onChange={(value) => {
                    history.push("/istoricLocatie", {
                      locatieId: value.value,
                      locationType: locationType,
                      locatieName: value.label,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {currentLocation && (
        <div onContextMenu={(e) => rightClickLocatie(e)}>
          <RightComponentWrapper
            label={currentLocation.type === "comenzi" ? "Furnizor" : "Locatie"}
            value={currentLocation?.locatie}
          />
          {currentLocation?.type !== "comenzi" &&
            currentLocation?.type !== "sediu" && (
              <RightComponentWrapper
                label={"Client"}
                value={currentLocation && currentLocation.client}
              />
            )}
          {currentLocation?.type === "psi" && (
            <RightComponentWrapper
              label={"Stingatoare"}
              value={`${
                currentLocation.terSt
                  ? `S: ${mapMonth(currentLocation.terSt)} \u00A0\u00A0\ `
                  : ``
              } ${currentLocation.instSt ? currentLocation.instSt : ""}`}
            />
          )}
          {currentLocation?.type !== "comenzi" && (
            <RightComponentWrapper
              label={
                currentLocation?.type !== "psi" ? "Instalatie" : "Hidranti"
              }
              value={
                currentLocation.type !== "psi"
                  ? currentLocation.descriere
                    ? currentLocation.descriere
                    : ""
                  : `${
                      currentLocation.terHid
                        ? `S: ${mapMonth(
                            currentLocation.terHid
                          )} \u00A0\u00A0\ `
                        : ``
                    } ${currentLocation.instHid ? currentLocation.instHid : ""}`
              }
            />
          )}
          {currentLocation.type === "ascensoare" &&
            currentLocation.terIscir && (
              <RightComponentWrapper
                label={"Scadenta"}
                value={`${mapMonth(
                  new Date(currentLocation.terIscir).getMonth() + 1
                )} ${new Date(currentLocation.terIscir).getFullYear()}`}
              />
            )}
          {currentLocation.type === "sediu" &&
            isLocatieCar(currentLocation.locatie) && (
              <>
                <RightComponentWrapper
                  label={"ITP"}
                  value={
                    currentLocation.itp
                      ? showDateFormatFullYear(new Date(currentLocation.itp))
                      : ""
                  }
                />
                <RightComponentWrapper
                  label={"Parcare"}
                  value={
                    currentLocation.parcare
                      ? showDateFormatFullYear(new Date(currentLocation.parcare))
                      : ""
                  }
                />
                <RightComponentWrapper
                  label={"RCA"}
                  value={
                    currentLocation.rca
                      ? showDateFormatFullYear(new Date(currentLocation.rca))
                      : ""
                  }
                />
                <RightComponentWrapper
                  label={"Rovigneta"}
                  value={
                    currentLocation.rovigneta
                      ? showDateFormatFullYear(
                          new Date(currentLocation.rovigneta)
                        )
                      : ""
                  }
                />
                <RightComponentWrapper
                  label={"Km pana la revizie"}
                  value={
                    currentLocation.kmRev && currentLocation.km
                      ? (currentLocation.kmRev + 10000) - currentLocation.km
                      : ""
                  }
                />
              </>
            )}
          {currentLocation.type === "psi" && (
            <RightComponentWrapper
              label={"Zona"}
              value={currentLocation?.zona}
            />
          )}
          {currentLocation.type !== "comenzi" &&
            currentLocation.type !== "sediu" && (
              <RightComponentWrapper
                label={"Adresa"}
                value={currentLocation && currentLocation.adresa}
              />
            )}
          {currentLocation.type !== "sediu" && (
            <RightComponentWrapper
              label={"Persoana"}
              value={currentLocation && currentLocation.persoana}
            />
          )}
          {currentLocation.type !== "sediu" && (
            <div onClick={() => addNrTelefon()}>
              <RightComponentWrapper
                label={"Telefon"}
                showBlue
                value={currentLocation && currentLocation.tel}
              />
            </div>
          )}
          {currentLocation.type !== "sediu" && (
            <RightComponentWrapper
              label={"E-Mail"}
              value={currentLocation && currentLocation.email}
            />
          )}
          {currentLocation.type !== "comenzi" &&
            currentLocation.type !== "sediu" && (
              <RightComponentWrapper
                label={"Contract"}
                value={currentLocation && currentLocation.contract}
              />
            )}

          {currentLocation.type !== "comenzi" &&
            currentLocation.type !== "sediu" && (
              <RightComponentWrapper
                label={"CUI"}
                value={currentLocation && currentLocation.cui}
              />
            )}
          {currentLocation.type === "ascensoare" && (
            <RightComponentWrapper
              label={"Firma"}
              value={currentLocation && currentLocation.firma}
            />
          )}
      <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "10px" }}>
            <div style={{display: "flex", flexDirection: "column"}}>{currentLocation.type !== "comenzi" &&
              currentLocation.type !== "sediu" &&
              currentLocation.type !== "diverse" &&
              currentLocation.type !== "administrativ" && (
                  <div className="locatie_toggle_wrapper">
                    <p
                      className={
                        currentLocation && currentLocation.activ
                          ? "inactiveP"
                          : "inactivActiv"
                      }
                    >
                      Inactiv
                    </p>
                    <Switch
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor={"#c60800"}
                      onChange={changeActive}
                      checked={currentLocation && currentLocation.activ}
                    />
                    <p
                      className={
                        currentLocation && currentLocation.activ
                          ? "activeActiveP"
                          : "inactiveP"
                      }
                    >
                      Activ
                    </p>
                  </div>
              )}

              {currentLocation.type === "ascensoare" && (isDispecer() || isAdmin()) && (
                  <div className="locatie_toggle_wrapper">
                    <Switch
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor={"#c60800"}
                      onChange={changeActiveRevSpec}
                      checked={currentLocation && currentLocation.revSpec}
                    />
                    <p
                      className={
                        currentLocation && currentLocation.revSpec
                          ? "activeActiveP"
                          : "inactiveP"
                      }
                    >
                      Revizie speciala
                    </p>
                  </div>
                )
              }
              {currentLocation?.type === "ascensoare" && (isDispecer() || isAdmin()) && (
                  <div className="locatie_toggle_wrapper">
                    <Switch
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor={"#c60800"}
                      onChange={changeActivCifru}
                      checked={currentLocation?.hasCifru}
                    />
                    <p
                      className={
                        currentLocation?.hasCifru == true
                          ? "activeActiveP"
                          : "inactiveP"
                      }
                    >
                      Cifru
                    </p>
                  </div>
                )
              }
              </div>
              <div style={{flexDirection: "column" }}>
                  {currentLocation && currentLocation.temporar && isDispecer() && (
                    <>
                      <ButtonComponent
                        text={"Sterge"}
                        onClick={() => setDeleteButtonPressed(true)}
                        variant={"danger"}
                      />
                      <ConfirmActionComponent
                        buttonIsPressed={deleteButtonPressed}
                        yesPressAction={deleteLocation}
                        noPressAction={() => setDeleteButtonPressed(false)}
                        text={`Esti sigur ca vrei sa stergi locatia ${currentLocation.locatie} ?`}
                      />
                    </>
                  )}
                  {isAdmin() && (
                    <>
                      <ButtonComponent
                        text={"Sterge"}
                        onClick={() => setDeleteButtonPressed(true)}
                        variant={"danger"}
                      />
                      <ConfirmActionComponent
                        buttonIsPressed={deleteButtonPressed}
                        yesPressAction={deleteLocation}
                        noPressAction={() => setDeleteButtonPressed(false)}
                        text={`Esti sigur ca vrei sa stergi locatia ${currentLocation.locatie} ?`}
                      />
                    </>
                  )}
              </div>
          
          </div>
          <ModalComponent
            show={locatieModal}
            size={
              isLocatieCar(currentLocation && currentLocation.locatie)
                ? "md"
                : "lg"
            }
            close={() => setLocatieModal(false)}
            headingText={
              currentLocation && currentLocation.temporar
                ? "Preluare Locatie"
                : "Modifica Locatie"
            }
            body={
              <Location
                closeModal={closeLocatieModal}
                refreshLocations={refreshLocations}
                locationType={currentLocation && currentLocation.type}
                locationId={currentLocation && currentLocation.id}
                locations={locations}
              />
            }
          />
        </div>
      )}
    </>
  );
};

export default Locatie;
